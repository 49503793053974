import React, { useEffect, useState } from "react";
import { Table, Modal, Image } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import {
  PAYOUT_INITIATED,
  PAYOUT_INPROGRESS,
  PAYOUT_COMPLETED,
  PAYOUT_FAILED,
  PAYOUT_REFUNDED
} from "../Constants/constant";

const ViewPayoutrequestModal = (props) => {

  const t = useTranslation("payout_transactions")

  const profile = useSelector((state) => state.admin.profile);

  const getStatusClass = (status) => {
    switch (status) {
      case PAYOUT_INITIATED:
        return "payment-initiated";
      case PAYOUT_INPROGRESS:
        return "payment-onhold";
      case PAYOUT_COMPLETED:
        return "payment-paid";
      case PAYOUT_FAILED:
        return "payment-cancelled";
      case PAYOUT_REFUNDED:
        return "payment-rejected";
      default:
        return "payment-initiated";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case PAYOUT_INITIATED:
        return "#5C9EEB";
      case PAYOUT_INPROGRESS:
        return "#FFCE22";
      case PAYOUT_COMPLETED:
        return "#197E23";
      case PAYOUT_FAILED:
        return "#FF8A00";
      case PAYOUT_REFUNDED:
        return "#818181";
      default:
        return "#5C9EEB";
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="view-transaction-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("payout_details")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="view-transaction-table">
          <Table>
            <tbody>
              <tr>
                <td>{t("settlement_table.payment_id")}</td>
                <td>{props.show.order_id}</td>
              </tr>
              <tr>
                <td>{t("settlement_table.utr_number")}</td>
                <td>{props.show.utr_number ? props.show.utr_number : "N/A"}</td>
              </tr>
              <tr>
                <td>{t("amount")}</td>
                <td>
                  <div className="transaction-amount">
                    {props.show.amount_formatted}
                  </div>
                </td>
              </tr>
              {profile.data.show_payout_commission ? (
                <>
                  <tr>
                    <td>{t("service_fee")}</td>
                    <td>
                      <div className="transaction-amount">
                        {props.show.service_fee_formatted}
                      </div>
                    </td>
                  </tr>
                </>
              ) : null}
              {profile.data.show_service_provider_commission ? (
                <tr>
                  <td>{t("tax_fee")}</td>
                  <td>
                    <div className="transaction-amount">
                      {props.show.tax_fee_formatted}
                    </div>
                  </td>
                </tr>
              ) : null}
              {props.show.payment_mode == "UPI" ? <React.Fragment>
                <tr>
                  <td>{t("upi_id.name")}</td>
                  <td>{props.show.upi_id}</td>
                </tr>
              </React.Fragment> : <React.Fragment>
                <tr>
                  <td>{t("beneficiaryAccount.name")}</td>
                  <td>{props.show.account_number}</td>
                </tr>
                <tr>
                  <td>{t("beneficiaryAddress.name")}</td>
                  <td>{props.show.account_holder_address}</td>
                </tr>
                <tr>
                  <td>{t("beneficiaryIFSC.name")}</td>
                  <td>{props.show.ifsc_code}</td>
                </tr>
              </React.Fragment>}
              <tr>
                <td>{t("beneficiaryName.name")}</td>
                <td>{props.show.account_holder_name}</td>
              </tr>
              <tr>
                <td>{t("paymentMode.name")}</td>
                <td>{props.show.payment_mode}</td>
              </tr>
              <tr>
                <td>{t("status")}</td>
                <td style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div className={getStatusClass(props.show.status)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      fill={getStatusColor(
                        props.show.status
                      )}
                      data-name="Layer 1"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0z"></path>
                    </svg>
                    {props.show.status_formatted}
                  </div>
                </td>
              </tr>
              <tr>
                <td>{t("created_at")}</td>
                <td>{props.show.created_at}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewPayoutrequestModal;
