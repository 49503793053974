import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Button,
  Dropdown,
  Image,
  Tab,
  Nav,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import PaymentLinkCreatedModal from "./PaymentLinkCreatedModal";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  invoicePaymentSaveStart,
  invoicePaymentViewStart,
} from "../../store/slices/PaymentSlice";
import { ButtonLoader } from "../Helper/Loader";
import PageLoader from "../Helper/PageLoader";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-multi-lang";
import Select from "react-select";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";

const CreateInvoicePaymentLink = (props) => {
  const t = useTranslation("payment");
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.admin.profile);
  const invoicePaymentView = useSelector(
    (state) => state.payment.invoicePaymentView
  );
  const invoicePaymentSave = useSelector(
    (state) => state.payment.invoicePaymentSave
  );
  const [paymentLinkCreated, setPaymentLinkCreated] = useState(false);
  const [paymentType, setPaymentType] = useState(1);
  const [skipRender, setSkipRender] = useState(true);
  const formRef = useRef(null);

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
      minWidth: "100px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      cursor: "pointer",
      minWidth: "auto",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#50506B80",
      fontSize: "1em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#DDE1E6!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#DDE1E6!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#50506B80",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
        "&:hover": {
          backgroundColor: "#f7f7f7",
          color: "#000",
        },
      };
    },
  };

  const inr_type_options = profile.data.pay_in_type == 1 ? [
    { value: "UPI", label: "UPI" },
    { value: "NEFT", label: "NEFT" },
    { value: "IMPS", label: "IMPS" },
    { value: "RTGS", label: "RTGS" },
  ] : profile.data.pay_in_type == 4 ?  [{ value: "UPI_INTENT", label: "UPI INTENT" }] : [{ value: "UPI", label: "UPI" }];

  const validationSchema = Yup.object().shape({
    amount: Yup.string()
      .test('invalid-number',t('create_payment.amount.invalid'), (value) => {
        if (!value) return true; 
        else {
          return !isNaN(value);
        }
      })
      .required(t('create_payment.amount.required'))
      .test('max', t('create_payment.amount.max'), (value) => {
        return value <= 10000000;
      }),  
    name: Yup.string()
      .required(t("create_payment.name.required"))
      .test(
        "len",
        t("create_payment.name.length"),
        (val) => !val || val.toString().length < 255
      ),
    payment_type: Yup.string().required(
      t("create_payment.payment_type.required")
    ),
    inr_type: Yup.string()
    .when("payment_type", {
      is: (payment_type) => payment_type === 'inr', 
      then: Yup.string().required(t("inr_type.required")),
    }),
    callback_url: Yup.string()
      .required(t("create_payment.callback_url.required"))
      .matches(
        /^((https?):\/\/)?(www\.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-_]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zAZ0-9-%]+&?)?$/,
        t("create_payment.callback_url.invalid_url")
      ),
    success_url: Yup.string()
      .required(t("create_payment.success_url.required"))
      .matches(
        /^((https?):\/\/)?(www\.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-_]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zAZ0-9-%]+&?)?$/,
        t("create_payment.success_url.invalid_url")
      ),
    failure_url: Yup.string()
      .required(t("create_payment.failure_url.required"))
      .matches(
        /^((https?):\/\/)?(www\.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-_]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zAZ0-9-%]+&?)?$/,
        t("create_payment.failure_url.invalid_url")
      ),
    cancel_url: Yup.string()
      .required(t("create_payment.cancel_url.required"))
      .matches(
        /^((https?):\/\/)?(www\.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-_]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zAZ0-9-%]+&?)?$/,
        t("create_payment.cancel_url.invalid_url")
      ),
    merchant_commission_percentage: Yup.number()
      .required(t("create_payment.merchant_commission_percentage.required"))
      .max(100, t("create_payment.merchant_commission_percentage.max"))
      .min(0, t("create_payment.merchant_commission_percentage.min")),
    order_id: Yup.string()
      .required(t("create_payment.order_id.required"))
      .test(
        "len",
        t("create_payment.order_id.length"),
        (val) => val && val.toString().length >= 5)
      .matches(/^[a-zA-Z0-9]+$/, t('create_payment.order_id.invalid'))
      .trim().notOneOf(['']),
    description: Yup.string().required(t("create_payment.description.required")).trim().notOneOf(['']),
    email: Yup.string().email(t("create_payment.email.invalid")).required(t("create_payment.email.required")),
    phone: Yup.string()
      .test("valid-mobile", t("create_payment.phone.invalid"), (value) => {
        if (!value) {
          return false;
        }
        return isValidPhoneNumber(value);
      })
      .required(t("create_payment.phone.required"))
      .matches(/^(?=.*[0-9])/, t("create_payment.phone.space_values_not_valid")),
    billing_city: Yup.string().required(t("create_payment.billing_city.required"))
      .matches(/^\S.*$/, t("create_payment.billing_city.required")).trim()
      .matches(/^[A-Za-z]+$/, t("create_payment.billing_city.invalid")),
    billing_state: Yup.string().required(t("create_payment.billing_state.required"))
      .matches(/^\S.*$/, t("create_payment.billing_city.required")).trim()
      .matches(/^[A-Za-z]+$/, t("create_payment.billing_state.invalid")),
    billing_country: Yup.string().required(t("create_payment.billing_country.required"))
      .matches(/^\S.*$/, t("create_payment.billing_country.required")).trim()
      .matches(/^[A-Za-z]+$/, t("create_payment.billing_country.invalid")),
    billing_zipcode: Yup.string()
      .test('invalid-number',t('create_payment.billing_zipcode.invalid'), (value) => {
        if (!value) return true; 
        else {
          return !isNaN(value);
        }
      })
      .required(t('create_payment.billing_zipcode.required')),
    primary_billing_address: Yup.string().required(t("create_payment.primary_billing_address.required")).trim().notOneOf(['']),
    secondary_billing_address: Yup.string().required(t("create_payment.secondary_billing_address.required")).trim().notOneOf(['']),
  });

  const closePaymentLinkCreatedModal = () => {
    setPaymentLinkCreated(false);
  };
  
  const handleSubmit = (values) => {
    dispatch(
      invoicePaymentSaveStart({
        ...values,
        invoice_link_id: params.paymentId || "",
      })
    );
  };

  useEffect(() => {
    if (!skipRender && Object.keys(invoicePaymentSave.data).length > 0)
      setPaymentLinkCreated(invoicePaymentSave.data.invoice_link);
    setSkipRender(false);
  }, [invoicePaymentSave]);

  useEffect(() => {
    if (
      !profile.loading &&
      Object.keys(profile.data).length > 0 &&
      params.paymentId
    )
      dispatch(invoicePaymentViewStart({ invoice_link_id: params.paymentId }));
  }, [params.paymentId]);

  useEffect(() => {
    if (
      !skipRender &&
      Object.keys(invoicePaymentView.data).length > 0 &&
      Object.keys(invoicePaymentView.data.invoice_link).length > 0
    ) {
      setPaymentType(invoicePaymentView.data.invoice_link.type);
    }
  }, [invoicePaymentView]);

  return (
    <>
      <div className="payment-link-sec">
        <div className="payment-link-box">
          {params.paymentId && invoicePaymentView.loading ? (
            <>
              <Skeleton height={60} width={250} className="mb-3" />
              <Skeleton height={400} />
            </>
          ) : (Object.keys(invoicePaymentView.data).length > 0 ||
              !params.paymentId) &&
            Object.keys(profile.data).length > 0 ? (
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <div className="create-payment-link-header-sec">
                <div className="create-payment-link-tab-nav-sec">
                  <Button className="back-btn" onClick={() => navigate(-1)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="12"
                      fill="none"
                      viewBox="0 0 8 12"
                    >
                      <path
                        fill="#090830"
                        fillRule="evenodd"
                        d="M6.063 11.276L.67 6.373a.494.494 0 010-.746L6.063.724a.903.903 0 011.19 0 .718.718 0 010 1.082L2.64 6l4.614 4.194c.328.299.328.783 0 1.082a.903.903 0 01-1.19 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </Button>
                  <h3>
                    {!params.paymentId
                      ? t("create_payment.invoice_create_title")
                      : t("create_payment.invoice_edit_title")}
                  </h3>
                </div>
              </div>
              <Formik
                initialValues={
                  params.paymentId
                    ? {
                        name: invoicePaymentView.data.invoice_link.name || "",
                        amount:
                          invoicePaymentView.data.invoice_link.amount || "",
                        description:
                          invoicePaymentView.data.invoice_link.description ||
                          "",
                        payment_type:
                          invoicePaymentView.data.invoice_link.payment_type ||
                          "",
                        inr_type:
                          invoicePaymentView.data.invoice_link.inr_type ||
                          "",
                        callback_url:
                          invoicePaymentView.data.invoice_link.callback_url ||
                          "",
                        success_url:
                          invoicePaymentView.data.invoice_link.success_url ||
                          "",
                        failure_url:
                          invoicePaymentView.data.invoice_link.failure_url ||
                          "",
                        cancel_url:
                          invoicePaymentView.data.invoice_link.cancel_url || "",
                        merchant_commission_percentage:
                          invoicePaymentView.data.invoice_link
                            .merchant_commission || "",
                        order_id:
                          invoicePaymentView.data.invoice_link.order_id || "",
                        // email: "",
                        // phone: "",
                        // billing_city: "",
                        // billing_state: "",
                        // billing_country: "",
                        // billing_zipcode: "",
                        // primary_billing_address: "",
                        // secondary_billing_address: "",
                      }
                    : {
                        name: "",
                        amount: "",
                        description: "",
                        payment_type: "",
                        inr_type: "",
                        callback_url: "",
                        success_url: "",
                        failure_url: "",
                        cancel_url: "",
                        merchant_commission_percentage: "",
                        order_id: "",
                        email: "",
                        phone: "",
                        billing_city: "",
                        billing_state: "",
                        billing_country: "",
                        billing_zipcode: "",
                        primary_billing_address: "",
                        secondary_billing_address: "",
                      }
                }
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                innerRef={formRef}
              >
                {({ values, setFieldValue, setFieldTouched, errors, touched }) => (
                  <FORM className="create-payment-link-form">
                    <div className="create-payment-link-sec">
                      <div className="create-payment-link-from-sec">
                        <Row>
                          <Col md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Choose Payment Method *</Form.Label>
                              <div className="payment-type-box">
                                {profile.data.upi_method ? (
                                  <label
                                    className="payment-type-item"
                                    htmlFor="payment-type-1"
                                  >
                                    <input
                                      name="payment_type"
                                      className="radio"
                                      id="payment-type-1"
                                      type="radio"
                                      checked={values.payment_type === "inr"}
                                      onChange={() =>
                                        setFieldValue("payment_type", "inr")
                                      }
                                      disabled={params.paymentId}
                                    />
                                    <div className="payment-type-card">
                                      <h4>{t("create_payment.inr")}</h4>
                                      <Image
                                        className="payment-type-icon"
                                        src={
                                          window.location.origin +
                                          "/img/payment-type/inr-icon.svg"
                                        }
                                        type="image/png"
                                      />
                                    </div>
                                  </label>
                                ) : null}
                                {profile.data.card_method ? (
                                  <label
                                    className="payment-type-item"
                                    htmlFor="payment-type-2"
                                  >
                                    <input
                                      name="payment_type"
                                      className="radio"
                                      id="payment-type-2"
                                      type="radio"
                                      checked={values.payment_type === "card"}
                                      onChange={() =>
                                        setFieldValue("payment_type", "card")
                                      }
                                      disabled={params.paymentId}
                                    />
                                    <div className="payment-type-card">
                                      <h4>{t("create_payment.card")}</h4>
                                      <Image
                                        className="payment-type-icon"
                                        src={
                                          window.location.origin +
                                          "/img/payment-type/card-icon.svg"
                                        }
                                        type="image/png"
                                      />
                                    </div>
                                  </label>
                                ) : null}
                                {profile.data.crypto_method ? (
                                  <label
                                    className="payment-type-item"
                                    htmlFor="payment-type-3"
                                  >
                                    <input
                                      name="payment_type"
                                      className="radio"
                                      id="payment-type-3"
                                      type="radio"
                                      checked={values.payment_type === "crypto"}
                                      onChange={() =>
                                        setFieldValue("payment_type", "crypto")
                                      }
                                      disabled={params.paymentId}
                                    />
                                    <div className="payment-type-card">
                                      <h4>{t("create_payment.crypto")}</h4>
                                      <Image
                                        className="payment-type-icon"
                                        src={
                                          window.location.origin +
                                          "/img/payment-type/crypto-icon.svg"
                                        }
                                        type="image/png"
                                      />
                                    </div>
                                  </label>
                                ) : null}
                              </div>
                              {!profile.data.upi_method &&
                              !profile.data.card_method &&
                              !profile.data.crypto_method ? (
                                <label>
                                  <div className="small-notes-info">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="15"
                                      fill="none"
                                      viewBox="0 0 13 13"
                                    >
                                      <path
                                        fill="#FF0000"
                                        fillRule="evenodd"
                                        d="M13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0zm-7.15 3.575a.65.65 0 101.3 0v-4.55a.65.65 0 10-1.3 0v4.55zm.65-6.5a.65.65 0 110-1.3.65.65 0 010 1.3z"
                                        clipRule="evenodd"
                                      ></path>
                                    </svg>
                                    <span style={{ color: "red" }}>
                                      {t("payment_method_not_found")}
                                    </span>
                                  </div>
                                </label>
                              ) : null}
                              <ErrorMessage
                                component={"div"}
                                name="payment_type"
                                className="errorMsg w-100"
                              />
                            </Form.Group>
                          </Col>
                          {values.payment_type === "inr" && (
                            <Col md={5}>
                              <Form.Group className="mb-4">
                                <Form.Label>{t("inr_type.label")} *</Form.Label>
                                <Select
                                  options={inr_type_options}
                                  styles={customStyles}
                                  isSearchable={false}
                                  value={
                                    params.bankAccountId && {
                                      label:
                                        values.inr_type
                                          .charAt(0)
                                          .toUpperCase() +
                                        values.inr_type.slice(1),
                                      value: values.inr_type,
                                    }
                                  }
                                  onChange={(selectedOption) => {
                                    setFieldValue(
                                      "inr_type",
                                      selectedOption.value
                                    );
                                  }}
                                  placeholder={t("inr_type.placeholder")}
                                />
                                <ErrorMessage
                                  component={"div"}
                                  name="inr_type"
                                  className="errorMsg w-100"
                                />
                              </Form.Group>
                            </Col>
                          )}
                        </Row>
                        <Row>
                          <Col md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                {t("create_payment.name.label")} *
                              </Form.Label>
                              <Field
                                className="form-control"
                                placeholder={t(
                                  "create_payment.name.placeholder"
                                )}
                                name="name"
                              />
                              <ErrorMessage
                                component={"div"}
                                name="name"
                                className="errorMsg w-100"
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                {t("create_payment.order_id.label")} *
                              </Form.Label>
                              <Field
                                className="form-control"
                                placeholder={t(
                                  "create_payment.order_id.placeholder"
                                )}
                                name="order_id"
                                type="text"
                              />
                              <ErrorMessage
                                component={"div"}
                                name="order_id"
                                className="errorMsg w-100"
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                {t("create_payment.callback_url.label")} *
                              </Form.Label>
                              <Field
                                className="form-control"
                                placeholder={t(
                                  "create_payment.callback_url.placeholder"
                                )}
                                name="callback_url"
                                // onChange={(e)=> setFieldValue('callback_url', e.target.value)}
                              />
                              <ErrorMessage
                                component={"div"}
                                name="callback_url"
                                className="errorMsg w-100"
                              />
                              <div className="small-notes-info">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  fill="none"
                                  viewBox="0 0 13 13"
                                >
                                  <path
                                    fill="#979BA2"
                                    fillRule="evenodd"
                                    d="M13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0zm-7.15 3.575a.65.65 0 101.3 0v-4.55a.65.65 0 10-1.3 0v4.55zm.65-6.5a.65.65 0 110-1.3.65.65 0 010 1.3z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                                <span>
                                  {t("create_payment.callback_url_info")}
                                </span>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                {t("create_payment.cancel_url.label")}*
                              </Form.Label>
                              <Field
                                className="form-control"
                                placeholder={t(
                                  "create_payment.cancel_url.placeholder"
                                )}
                                name="cancel_url"
                                // onChange={(e)=> setFieldValue('callback_url', e.target.value)}
                              />
                              <ErrorMessage
                                component={"div"}
                                name="cancel_url"
                                className="errorMsg w-100"
                              />
                              <div className="small-notes-info">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  fill="none"
                                  viewBox="0 0 13 13"
                                >
                                  <path
                                    fill="#979BA2"
                                    fillRule="evenodd"
                                    d="M13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0zm-7.15 3.575a.65.65 0 101.3 0v-4.55a.65.65 0 10-1.3 0v4.55zm.65-6.5a.65.65 0 110-1.3.65.65 0 010 1.3z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                                <span>
                                  {t("create_payment.cancel_url_info")}
                                </span>
                              </div>
                            </Form.Group>
                            <Form.Group className="mb-3" 
                              controlId="exampleForm.ControlInput1">
                              <Form.Label
                                for="exampleInputEmail1"
                                className="form-label"
                              >
                                {t("create_payment.email.label")} *
                              </Form.Label>
                              <Field
                                className="form-control"
                                placeholder={t("create_payment.email.placeholder")}
                                type="text"
                                name="email"
                              />
                              <ErrorMessage
                                component={"div"}
                                name="email"
                                className="errorMsg"
                              />
                            </Form.Group>
                            <Form.Group className="mb-3" 
                              controlId="exampleForm.ControlInput1">
                              <Form.Label
                                for="exampleInputEmail1"
                                className="form-label"
                              >
                                {t("create_payment.primary_billing_address.label")} *
                              </Form.Label>
                              <Field
                                className="form-control"
                                placeholder={t("create_payment.primary_billing_address.placeholder")}
                                type="text"
                                name="primary_billing_address"
                              />
                              <ErrorMessage
                                component={"div"}
                                name="primary_billing_address"
                                className="errorMsg"
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                {t("create_payment.billing_city.label")} *
                              </Form.Label>
                              <Field
                                className="form-control"
                                placeholder={t(
                                  "create_payment.billing_city.placeholder"
                                )}
                                name="billing_city"
                              />
                              <ErrorMessage
                                component={"div"}
                                name="billing_city"
                                className="errorMsg w-100"
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                {t("create_payment.billing_country.label")} *
                              </Form.Label>
                              <Field
                                className="form-control"
                                placeholder={t(
                                  "create_payment.billing_country.placeholder"
                                )}
                                name="billing_country"
                              />
                              <ErrorMessage
                                component={"div"}
                                name="billing_country"
                                className="errorMsg w-100"
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                {t("create_payment.amount.label")}*
                              </Form.Label>
                              <Field
                                className="form-control"
                                placeholder={t("create_payment.amount.placeholder")}
                                name="amount"
                                type="text" 
                                pattern="\d*" 
                                onKeyDown={(e) => {
                                  if (!/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              <ErrorMessage
                                component={"div"}
                                name="amount"
                                className="errorMsg w-100"
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                {t(
                                  "create_payment.merchant_commission_percentage.label"
                                )}{" "}
                                *
                              </Form.Label>
                              <Field
                                className="form-control"
                                placeholder={t(
                                  "create_payment.merchant_commission_percentage.placeholder"
                                )}
                                name="merchant_commission_percentage"
                                type="number"
                              />
                              <ErrorMessage
                                component={"div"}
                                name="merchant_commission_percentage"
                                className="errorMsg w-100"
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                {t("create_payment.success_url.label")} *
                              </Form.Label>
                              <Field
                                className="form-control"
                                placeholder={t(
                                  "create_payment.success_url.placeholder"
                                )}
                                name="success_url"
                              />
                              <ErrorMessage
                                component={"div"}
                                name="success_url"
                                className="errorMsg w-100"
                              />
                              <div className="small-notes-info">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  fill="none"
                                  viewBox="0 0 13 13"
                                >
                                  <path
                                    fill="#979BA2"
                                    fillRule="evenodd"
                                    d="M13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0zm-7.15 3.575a.65.65 0 101.3 0v-4.55a.65.65 0 10-1.3 0v4.55zm.65-6.5a.65.65 0 110-1.3.65.65 0 010 1.3z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                                <span>
                                  {t("create_payment.success_url_info")}
                                </span>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                {t("create_payment.failure_url.label")} *
                              </Form.Label>
                              <Field
                                className="form-control"
                                placeholder={t(
                                  "create_payment.failure_url.placeholder"
                                )}
                                name="failure_url"
                              />
                              <ErrorMessage
                                component={"div"}
                                name="failure_url"
                                className="errorMsg w-100"
                              />
                              <div className="small-notes-info">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  fill="none"
                                  viewBox="0 0 13 13"
                                >
                                  <path
                                    fill="#979BA2"
                                    fillRule="evenodd"
                                    d="M13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0zm-7.15 3.575a.65.65 0 101.3 0v-4.55a.65.65 0 10-1.3 0v4.55zm.65-6.5a.65.65 0 110-1.3.65.65 0 010 1.3z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                                <span>
                                  {t("create_payment.failure_url_info")}
                                </span>
                              </div>
                            </Form.Group>
                            <Form.Group className="mb-3 register-phone-input">
                              <Form.Label
                                htmlFor="mobile"
                                className="form-label"
                              >
                                {t("create_payment.phone.label")} *
                              </Form.Label>
                              <PhoneInput
                                name="phone"
                                defaultCountry="IN"
                                placeholder={t("create_payment.phone.placeholder")}
                                onChange={(value) =>
                                  setFieldValue("phone", value)
                                }
                                onBlur={() => formRef.current.submitForm()}
                                international
                                className={`${
                                  touched.mobile && errors.mobile
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                component={"div"}
                                name="phone"
                                className="errorMsg"
                              />
                            </Form.Group>
                            <Form.Group className="mb-3" 
                              controlId="exampleForm.ControlInput1">
                              <Form.Label
                                for="exampleInputEmail1"
                                className="form-label"
                              >
                                {t("create_payment.secondary_billing_address.label")} *
                              </Form.Label>
                              <Field
                                className="form-control"
                                placeholder={t("create_payment.secondary_billing_address.placeholder")}
                                type="text"
                                name="secondary_billing_address"
                              />
                              <ErrorMessage
                                component={"div"}
                                name="secondary_billing_address"
                                className="errorMsg"
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                {t("create_payment.billing_state.label")} *
                              </Form.Label>
                              <Field
                                className="form-control"
                                placeholder={t(
                                  "create_payment.billing_state.placeholder"
                                )}
                                name="billing_state"
                              />
                              <ErrorMessage
                                component={"div"}
                                name="billing_state"
                                className="errorMsg w-100"
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                {t("create_payment.billing_zipcode.label")}*
                              </Form.Label>
                              <Field
                                className="form-control"
                                placeholder={t("create_payment.billing_zipcode.placeholder")}
                                name="billing_zipcode"
                                type="text" 
                                pattern="\d*" 
                                onKeyDown={(e) => {
                                  if (!/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              <ErrorMessage
                                component={"div"}
                                name="billing_zipcode"
                                className="errorMsg w-100"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <Form.Group
                              className="mb-0"
                              controlId="exampleForm.ControlTextarea1"
                            >
                              <Form.Label>Description</Form.Label>
                              <Field
                                as="textarea"
                                rows="4"
                                className="form-control"
                                placeholder="Enter Description"
                                name="description"
                                // onChange={(e)=> setFieldValue('callback_url', e.target.value)}
                              />
                            </Form.Group>
                            <ErrorMessage
                              component={"div"}
                              name="description"
                              className="errorMsg w-100"
                            />
                          </Col>
                        </Row>
                      </div>
                      <div className="payment-link-btn-sec mt-3">
                        <Button
                          type="submit"
                          className="efi-payment-btn"
                          disabled={
                            invoicePaymentSave.buttonDisable ||
                            (!profile.data.upi_method &&
                              !profile.data.card_method &&
                              !profile.data.crypto_method)
                          }
                        >
                          {invoicePaymentSave.buttonDisable ? (
                            <ButtonLoader variant="black" />
                          ) : params.paymentId ? (
                            t("create_payment.update")
                          ) : (
                            t("create_payment.create")
                          )}
                        </Button>
                      </div>
                    </div>
                  </FORM>
                )}
              </Formik>
            </Tab.Container>
          ) : null}
        </div>
      </div>
      {paymentLinkCreated && (
        <PaymentLinkCreatedModal
          paymentId={params.paymentId || ""}
          paymentLinkCreated={paymentLinkCreated}
          closePaymentLinkCreatedModal={closePaymentLinkCreatedModal}
          setPaymentLinkCreated={setPaymentLinkCreated}
        />
      )}
    </>
  );
};

export default CreateInvoicePaymentLink;
