//Type
export const PAY_IN = 1;
export const PAY_OUT = 2;

//PayIn transaction status
export const TRANSACTION_INITIATED = 0;
export const TRANSACTION_PROCESSING = 1;
export const TRANSACTION_SUCCESS = 2;
export const TRANSACTION_REJECTED = 3;
export const TRANSACTION_CANCELLED = 4;
export const TRANSACTION_FAILED = 5;
export const TRANSACTION_CHARGEBACK = 6;

//PayOut deposit status
export const PAYOUT_DEPOSIT_INITIATED = 1;
export const PAYOUT_DEPOSIT_INPROGRESS = 2;
export const PAYOUT_DEPOSIT_COMPLETED = 3;
export const PAYOUT_DEPOSIT_FAILED = 4;

//PayOut type
export const PAY_OUT_TYPE_ZYRO = 1;
export const PAY_OUT_TYPE_HAODA = 2;
export const PAY_OUT_TYPE_AXIS = 3;
export const PAY_OUT_TYPE_HAODA_BANK = 4;

//PayOut transaction status
export const PAYOUT_INITIATED = 1;
export const PAYOUT_INPROGRESS = 2;
export const PAYOUT_COMPLETED = 3;
export const PAYOUT_FAILED = 4;
export const PAYOUT_REFUNDED = 5;
